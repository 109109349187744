export const BASE_URL = process.env.REACT_APP_BASE_URL
export const PAYMENT_BASE_URL = process.env.REACT_APP_PAYMENT_BASE_URL

// export const ADMIN_USERS = process.env.REACT_APP_ADMIN_USERS
export const ADMIN_USERS = [
  'piyoosh@mwfbi.com',
  'piyoosh-a@mwfbi.com',
  'ewood@mwfbi.com',
  'jbartholom@mwfbi.com',
  'asopr@mwfbi.com', 'mflores@mwfbi.com', 'spena@mwfbi.com','jhowdeshell@mwfbi.com'
];

// export const BASE_URL = 'http://159.65.37.101:3001/api';
// export const PAYMENT_BASE_URL = 'http://159.65.37.101:3001/';
// export const BASE_URL = 'http://localhost/api'
// export const PAYMENT_BASE_URL = 'http://localhost'

// export const BASE_URL = 'https://staging-360.azurewebsites.net/api'
// export const PAYMENT_BASE_URL = 'https://staging-360.azurewebsites.net'

export const PAYMENT_US_URL = process.env.REACT_APP_PAYMENT_US_URL; //Quick Pay
export const PORTAL_PAYMENT_REDIRECTION = process.env.REACT_APP_PORTAL_PAYMENT_REDIRECTION; //OneInc Wallet,
